<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="texto"
        clearable
        filled
        clear-icon="mdi-close-circle"
        label="Buscar productos"
        type="text"
        append-icon="mdi-magnify"
        @click:append="buscador()"
        @keydown.enter="buscador()"
        @click:clear="quitarTexto()"
        class="mb-n4"
      ></v-text-field>
      <v-chip class="ma-2" v-if="categoria" close @click:close="quitarCategoria()"
        >{{ categoria.nombre }}
      </v-chip>
      <v-btn
        block
        color="primary"
        @click.stop="$store.dispatch('cambiarMenuCategorias')"
        >Ver categorias</v-btn
      >
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Tienda_Filtros",
  props: {
    textoInicial: { type: String, required: false, default: null },
    categoriaInicial: { type: String, required: false, default: null },
  },
  data: () => ({
    texto: "",
    categoria: null,
  }),
  methods: {
    buscador() {
      let url = `/tienda`
      if(this.categoria) url += `/${this.categoriaInicial}`
      if(this.texto) url += `?buscar=${this.texto}`
      if(this.$route.fullPath != url) this.$router.push(url)
      this.$emit("buscar", {
        texto: this.texto,
        categoria: this.categoria,
      });
    },
    async obtenerCategoria() {
      try {
        const categoria = await this.axios.get(
          "/categoria/" + this.categoriaInicial
        );
        if (categoria.data) this.categoria = categoria.data;
        this.buscador()
      } catch (error) {
        console.log(error);
      }
    },
    setTextoInicial() {
      this.texto = this.textoInicial
      this.buscador()
    },
    quitarCategoria() {
      this.categoria = null
      this.buscador()
    },
    quitarTexto() {
      this.texto = ""
      this.buscador()
    }
  },
  watch: {
    textoInicial() {
      if (this.textoInicial) this.buscador()
    },
    async categoriaInicial() {
      if (this.categoriaInicial) await this.obtenerCategoria()
    },
  },
  async created() {
    if (this.categoriaInicial) await this.obtenerCategoria();
    if (this.textoInicial) this.setTextoInicial()
  },
};
</script>
