<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <Tienda_Filtros
      :textoInicial="textoInicial"
      :categoriaInicial="categoriaInicial"
      @buscar="buscarProducto"
    ></Tienda_Filtros>
    <Tienda_Resultados
      :productos="productos"
      :cargando="cargando"
      :titulo="titulo"
    ></Tienda_Resultados>
    <v-pagination
      v-model="paginacion.page"
      :length="paginacion.totalPages"
      @input="obtenerProductos"
    ></v-pagination>
  </v-container>
</template>

<script>
import Tienda_Filtros from "../components/Tienda/Filtros";
import Tienda_Resultados from "../components/Tienda/Resultados";
export default {
  name: "Tienda",
  metaInfo: {
    title: "Tienda Online",
  },
  components: {
    Tienda_Filtros,
    Tienda_Resultados,
  },
  props: {},
  data: () => ({
    productos: [],
    cargando: false,
    textoInicial: null,
    categoriaInicial: null,
    titulo: "Te puede interesar",
    paginacion: {},
    parametros: { mayorStock: true },
    breadcrumbs: [
      {
        text: "Ver Mis Pedidos",
        disabled: false,
        to: "/cuenta",
      },
    ],
  }),
  methods: {
    buscarProducto(parametros) {
      this.parametros = parametros;
      this.paginacion = {};
      this.titulo = parametros.texto ? `Resultados de ${parametros.texto}` : 'Te puede interesar'
      if (parametros.categoria)
        this.titulo += ` en ${parametros.categoria.nombre}`;
      this.obtenerProductos();
    },
    async obtenerProductos() {
      this.cargando = true;
      let paginacion = { ...this.paginacion, limit: 10 };
      let parametros = this.parametros;
      try {
        let consulta = await this.axios.post("/productos", {
          parametros,
          paginacion,
        });
        let datos = consulta.data;
        this.paginacion = {
          page: datos.page,
          limit: datos.limit,
          totalPages: datos.totalPages,
          totalDocs: datos.totalDocs,
        };
        this.productos = datos.docs;
      } catch (error) {
        console.log(error);
      }
      this.cargando = false;
    },
  },
  created() {
    if (this.$route.query.buscar) {
      this.textoInicial = this.$route.query.buscar;
    }
    if (this.$route.params.categoria) {
      this.categoriaInicial = this.$route.params.categoria;
    }
    if (!this.$route.params.categoria && !this.$route.query.buscar)
      this.obtenerProductos();
  },
  watch: {
    "$route.query.buscar"() {
      if (this.$route.query.buscar) {
        this.textoInicial = this.$route.query.buscar;
      }
    },
    "$route.params.categoria"() {
      if (this.$route.params.categoria) {
        this.categoriaInicial = this.$route.params.categoria;
      }
    },
  },
};
</script>
