<template>
    <v-card class="mt-2">
      <v-card-text
        ><ListadoProductos
          :items="productos"
          :titulo="titulo"
          :cargando="cargando"
        ></ListadoProductos></v-card-text
    ></v-card>
</template>
<script>
import ListadoProductos from "../ListadoProductos"
export default {
    name: 'Tienda_Resultados',
    components: {ListadoProductos},
    props: {
        productos: { type: Array, required: true, default: [] },
        titulo: { type: String, required: true, default: null },
        cargando: { type: Boolean, required: false, default: false }
    }
}
</script>