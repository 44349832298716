<template>
  <v-list three-line>
    <template>
      <v-subheader>{{ titulo }}</v-subheader>
      <v-divider></v-divider>
      <v-skeleton-loader
        type="list-item-avatar-three-line"
        v-if="cargando"
      ></v-skeleton-loader>
      <v-list-item v-else v-for="(item, index) in items" :key="index">
        <v-list-item-avatar tile @click="irProducto(item)">
          <v-badge
            bordered
            bottom
            :color="item.stock > 0 ? 'green' : 'red'"
            dot
            offset-x="10"
            offset-y="10"
          >
            <v-avatar size="40" tile>
              <v-img :src="item.foto"></v-img>
            </v-avatar>
          </v-badge>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
          @click="irProducto(item)"
            >{{ item.nombre }}</v-list-item-title
          >
          <v-list-item-subtitle>
            <div v-if="item.oferta">
              <b class="text-decoration-line-through"
                >$ {{ item.precio_original }}</b
              >
              <h3 class="red--text">$ {{ item.precio }}</h3>
            </div>
            <div v-else>
              <b>$ {{ item.precio }}</b>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon v-if="item.stock > 0" @click="dialogAgregarCarrito({ item, estado: true })">
            <v-icon color="green lighten-1">mdi-cart-plus</v-icon>
          </v-btn>
          <v-btn icon v-else-if="false">
            <v-icon color="orange lighten-1">mdi-bell-ring-outline</v-icon>
          </v-btn>
          <v-btn icon
            ><v-icon color="red lighten-1">{{
              item.favorito ? "mdi-heart" : "mdi-heart-outline"
            }}</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-alert color="blue" outlined v-if="!cargando && items.length == 0"
        >No se encontraron productos con el criterio de busqueda ingresado</v-alert
      >
    </template>
  </v-list>
</template>
<script>
import {mapMutations} from 'vuex'
export default {
  name: "ListadoProductos",
  props: {
    titulo: { required: true, type: String },
    items: { required: true, type: Array },
    cargando: { required: false, type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {
    ...mapMutations(['dialogAgregarCarrito']),
    irProducto(item) {
      let obj = { name: 'Productos', params: { slug: item.slug, anterior: this.$route } }
      this.$router.push(obj)
    }
  },
};
</script>
